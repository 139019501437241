export const environment = {
  production: false,
  api_url: 'https://test.mobiquity-one.com/invoice-payments',
  api_auth_url: 'https://test.mobiquity-one.com/authorization',
  smart_routing: 'https://test.mobiquity-one.com/smart-routing/smart-routing',
  caching_api_url: 'https://test.mobiquity-one.com/caching',
  api_mpg_url: 'https://test.mobiquity-one.com/ecom',
  api_mpg_wallet_url: 'https://test.mobiquity-one.com',
  ecomtoken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Imp3cy1rZXktcHVibGljLTEifQ.eyJzY29wZSI6WyJHRVRfVVNFUl9BTkRfQUNDT1VOVF9ERVRBSUxTIiwiU0VMRlNFVEFVVEhGQUNUT1IiLCJGRVRDSF9VU0VSX1FVRVNUSU9OIiwiVFhOQ09SUkVDVCIsIkFUTUNBU0hPVVQiLCJBVE1DQVNIT1VUX1Y0IiwiQURNVFhOUkVGSUQiXSwiZXhwIjoxNzQ1MTI2MzY5LCJhdXRob3JpdGllcyI6WyJST0xFX0NMSUVOVCJdLCJqdGkiOiJlN2JiMGY0Zi05ZWNhLTRlMDUtOWQyZC1jZTI0ZmYwYTgxODUiLCJjbGllbnRfaWQiOiJDb3JlV2ViIn0.gZL1a5EMugHTiSVXwnjc3G6PJrCRnqK9kL3Q0z6nmyWg8ksXlaJFzY7RiSdGsoj_8VpfuSexiLzb0MSjKvSAUF_6-HyK1ntdrNo8chM1fSSFdsqQWccUqHUUI1rLvrHGEYEACWK4EfnErlBI9FLC-c7d2EJA861fjmh5GjJkq9wwX1ehtpZ3FhAJNNhMHU0vbsQZqWcedl3b1uvITn_boSKwi9Hde8hUz6Z2ll1YyPqfsVQOj8mGkTka9KTOXX6lQTutYCM0uRXCoGR_NFJE1kF-1ivtzAvITd7-q4iGSQEp84ULBUMAaXE5SO_gR_uofrV5DfilKe9hKp4FL1SSCw',
  invtoken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Imp3cy1rZXktcHVibGljLTEifQ.eyJzY29wZSI6WyJHRVRfVVNFUl9BTkRfQUNDT1VOVF9ERVRBSUxTIiwiU0VMRlNFVEFVVEhGQUNUT1IiLCJGRVRDSF9VU0VSX1FVRVNUSU9OIiwiVFhOQ09SUkVDVCIsIkFUTUNBU0hPVVQiLCJBVE1DQVNIT1VUX1Y0IiwiQURNVFhOUkVGSUQiXSwiZXhwIjoxNzQ1MTI2MzY5LCJhdXRob3JpdGllcyI6WyJST0xFX0NMSUVOVCJdLCJqdGkiOiJlN2JiMGY0Zi05ZWNhLTRlMDUtOWQyZC1jZTI0ZmYwYTgxODUiLCJjbGllbnRfaWQiOiJDb3JlV2ViIn0.gZL1a5EMugHTiSVXwnjc3G6PJrCRnqK9kL3Q0z6nmyWg8ksXlaJFzY7RiSdGsoj_8VpfuSexiLzb0MSjKvSAUF_6-HyK1ntdrNo8chM1fSSFdsqQWccUqHUUI1rLvrHGEYEACWK4EfnErlBI9FLC-c7d2EJA861fjmh5GjJkq9wwX1ehtpZ3FhAJNNhMHU0vbsQZqWcedl3b1uvITn_boSKwi9Hde8hUz6Z2ll1YyPqfsVQOj8mGkTka9KTOXX6lQTutYCM0uRXCoGR_NFJE1kF-1ivtzAvITd7-q4iGSQEp84ULBUMAaXE5SO_gR_uofrV5DfilKe9hKp4FL1SSCw',
  loyalty_url: 'https://test.mobiquity-one.com/loyalty/loyalty',  
  service_integrator_url: 'https://test.mobiquity-one.com/service-integrator/ccavenue',
  redirectHtmlFlag: 'TRUE',
  retryCount: '2',
  successTimeout: '2',
  failureTimeout: '5'
};
